import { computed } from 'vue';
import { useStore } from 'vuex';
import { removeSideSlashes } from '@/utils/string.utils';

// TODO: после мержа задачи 6158 подумать над переименованием хука (например, useCommonn и т.п.),
//  так как хочется вынести сюда не только геттеры, но и всякие общие функции с использованием стора )
const useSiteInfo = () => {
  const store = useStore();

  const websiteId = computed(() => {
    return store.getters.websiteId;
  });

  const isRuSite = computed<boolean>(() => {
    return store.getters.isRuSite;
  });

  const isAmSite = computed<boolean>(() => {
    return store.getters.isAmSite;
  });

  const isKzSite = computed<boolean>(() => {
    return store.getters.isKzSite;
  });

  const isUzSite = computed<boolean>(() => {
    return store.getters.isUzSite;
  });

  const isKgSite = computed<boolean>(() => {
    return store.getters.isKgSite;
  });

  const isAzSite = computed<boolean>(() => {
    return store.getters.isAzSite;
  });

  const isBySite = computed<boolean>(() => {
    return store.getters.isBySite;
  });

  const isGeSite = computed<boolean>(() => {
    return store.getters.isGeSite;
  });

  const currentLocaleISO = computed<string>(() => {
    return store.getters.currentLocaleISO;
  });

  const currentLocale = computed<string>(() => {
    return store.getters.currentLocale;
  });

  const isLoggedInCabinet = computed<boolean>(() => {
    return store.getters.isLoggedInCabinet;
  });

  const getLinkWithLocale = (link: string) => {
    const removedSlashLink = removeSideSlashes(link);

    return `/${currentLocale.value}/${removedSlashLink}/`;
  };

  return {
    websiteId,
    isRuSite,
    isKzSite,
    isAmSite,
    isUzSite,
    isKgSite,
    isAzSite,
    isBySite,
    isGeSite,
    currentLocaleISO,
    currentLocale,
    getLinkWithLocale,
    isLoggedInCabinet,
  };
};

export default useSiteInfo;
